import React, { memo, useEffect } from "react"
import { graphql, useStaticQuery , navigate } from "gatsby"

const query = graphql`
	query {
		allContentfulEngineeringPost(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					title
					slug
                    author
                    date
                    tag
					category
					content {
						content
					}
				}
			}
		}
		contentfulPage(slug: { eq: "engineering" }) {
			hideFooterDemo
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
			}
		}
	}
`

const Engineering = () => {
	const { allContentfulEngineeringPost } = useStaticQuery(query)
	const engineeringPosts = allContentfulEngineeringPost.edges
	
	useEffect(() => {
		navigate(`/engineering/${engineeringPosts[0]?.node?.slug}`)
	}, [])

	return (
		<div />
	)
}

export default memo(Engineering)
